import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { AppLogo } from '../../assets'
import useGlobalStore from '../../store/globalStore'

const drawerWidth = 240

const AppBar = styled(MuiAppBar)(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const AppHeader = ({ open, handleDrawerOpen }) => {
  const user = useGlobalStore((state) => state.user)
  const userName = user.attributes?.name

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <Box width="100%" display="flex" pl={4}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 10,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            display="flex"
            flexGrow={1}
            alignItems="center"
            // When drawer open icon will be hidden, so align to end
            justifyContent={open ? 'flex-end' : 'space-between'}
            pr={2}
          >
            {/* Hide icon when drawer open */}
            <Box
              sx={{
                ...(open && { display: 'none' }),
              }}
            >
              <AppLogo width="40px" height="40px" />
            </Box>

            <IconButton color="inherit">
              <AccountCircle fontSize="large" sx={{ mr: 4 }} />
              <Typography>{userName || 'Wolo Admin'}</Typography>
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default AppHeader
