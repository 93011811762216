import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { axiosPrivate } from '../../api/axiosClient'
import { apiEndpoints, itemsPerPage, queryKeys } from '../../constants'
import { getTrimmedValueForDeletedAccount } from '../../utils'

const useFetchRegisteredUsers = (page, searchTerm, userType) => {
  const { data, isLoading } = useQuery(
    [queryKeys.registeredUsers, page, searchTerm, userType],
    () => {
      const searchTermAvailable = !!searchTerm
      const searchUserType = !!userType

      let endpoint = `${apiEndpoints.registeredUsers}?page=${page}&limit=${itemsPerPage}`

      if (searchTermAvailable) endpoint += `&search=${searchTerm}`
      if (searchUserType && userType !== 'all') endpoint += `&userType=${userType}`

      return axiosPrivate.get(endpoint)
    },
    {
      enabled: !!axiosPrivate,
    },
  )

  const usersList = useMemo(() => {
    return data
      ? data?.data?.map((userInfo) => {
        const email = userInfo.attributes?.email
        const username = userInfo.attributes?.username
        const isAccountDeleted = !!userInfo.attributes?.deletedAt
        const premiumStatus = userInfo.attributes?.premiumStatus ? 'Premium' : 'Free'

        const userEmail = isAccountDeleted
          ? getTrimmedValueForDeletedAccount(email)
          : email
        const updatedUsername = isAccountDeleted
          ? getTrimmedValueForDeletedAccount(username)
          : username

        const updatedUserInfo = {
          ...userInfo,
          ...userInfo.attributes,
          email: userEmail,
          username: updatedUsername,
          premiumStatus: premiumStatus
        }
        delete updatedUserInfo.attributes

        return updatedUserInfo
      }) || []
      : []
  }, [data])

  return {
    data: usersList,
    isLoading,
    totalPages: data?.meta?.last || 0,
  }
}

export default useFetchRegisteredUsers
