import { Navigate, Outlet } from 'react-router-dom'
import { DashboardWrapper } from '../components/organisms'
import { routes } from '../constants'

const PrivateRoutes = ({ isAuth }) => {
  return isAuth ? (
    <DashboardWrapper>
      <Outlet />
    </DashboardWrapper>
  ) : (
    <Navigate to={routes.login} replace />
  )
}

export default PrivateRoutes
