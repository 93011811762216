import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  width: '30vw',
  boxShadow: 24,
  p: 6,
  borderRadius: 2,
}

const ModalWrapper = ({
  isOpen,
  onClose,
  title,
  width,
  padding,
  showCloseIcon,
  children,
  sxProps,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
          ...(width && { width }),
          ...(padding && { p: padding }),
          ...sxProps,
        }}
      >
        {showCloseIcon && (
          <Box
            display="flex"
            justifyContent={title ? 'space-between' : 'flex-end'}
            alignItems="center"
            mb={2}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>

            <IconButton onClick={onClose} sx={{ p: 1 }}>
              <CloseIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </Box>
        )}

        {children}
      </Box>
    </Modal>
  )
}

export default ModalWrapper
