import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'

export const StyledText = styled(Typography)`
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
`

const CommentBox = ({ text }) => {
  return (
    <Box
      alignItems="center"
      backgroundColor="#EDEDED"
      padding="12px"
      borderRadius="8px"
    >
      <StyledText>{text}</StyledText>
    </Box>
  )
}

export default CommentBox
