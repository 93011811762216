import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import useGlobalStore from '../../store/globalStore'
import {
  getErrorMessage,
  getFormattedDateFromISO,
  getRegisteredUserStatus,
  getTrimmedValueForDeletedAccount,
} from '../../utils'
import { axiosPrivate } from '../../api/axiosClient'
import { apiEndpoints, queryKeys } from '../../constants'

const useFetchAllRegisteredUsers = () => {
  const showToastMessage = useGlobalStore((state) => state.showToastMessage)

  const { data, isFetching, isSuccess, refetch } = useQuery(
    [queryKeys.allRegisteredUsers],
    () => axiosPrivate.get(apiEndpoints.registeredUsers),
    {
      // Only call endpoint when refetch method is called
      enabled: false,
      onError: (error) => {
        const errorMessage = getErrorMessage(error)
        showToastMessage(errorMessage, 'error')
      },
    },
  )

  const usersList = useMemo(() => {
    return data
      ? data?.data?.map((userInfo, index) => {
        const email = userInfo.attributes?.email
        const username = userInfo.attributes?.username
        const isAccountDeleted = !!userInfo.attributes?.deletedAt

        const { title } = getRegisteredUserStatus(
          userInfo.attributes?.status,
          userInfo.attributes?.deletedAt,
        )

        const userEmail = isAccountDeleted
          ? getTrimmedValueForDeletedAccount(email)
          : email
        const updatedUsername = isAccountDeleted
          ? getTrimmedValueForDeletedAccount(username)
          : username

        const updatedUserInfo = {
          ...userInfo,
          serialNo: index + 1,
          registeredOn: getFormattedDateFromISO(
            userInfo.attributes?.createdAt,
            'longDateFormat',
          ),
          ...userInfo.attributes,
          userStatus: title,
          premiumStatus: userInfo.attributes.premiumStatus ? 'Premium' : 'Free',
          email: userEmail,
          username: updatedUsername,
        }

        delete updatedUserInfo.attributes

        return updatedUserInfo
      }) || []
      : []
  }, [data])

  return {
    allUsersList: usersList,
    isFetchingAllUsers: isFetching,
    fetchAllUsers: refetch,
    isFetchAllUsersSuccess: isSuccess,
  }
}

export default useFetchAllRegisteredUsers
