import { useMemo } from 'react'
import build from 'redux-object'
import normalize from 'json-api-normalizer'
import { useQuery } from '@tanstack/react-query'

import { axiosPrivate } from '../../api/axiosClient'
import { sortArrayByProperty } from '../../utils'
import { apiEndpoints, itemsPerPage, queryKeys } from '../../constants'

const useFetchReportedPics = (page, searchTerm) => {
  const { data, isLoading } = useQuery(
    [queryKeys.reportedPics, page, searchTerm],
    () => {
      const searchTermAvailable = !!searchTerm

      let endpoint = `${apiEndpoints.reportedPics}?page=${page}&limit=${itemsPerPage}`

      if (searchTermAvailable) endpoint += `&search=${searchTerm}`

      return axiosPrivate.get(endpoint)
    },
    {
      enabled: !!axiosPrivate,
    },
  )

  const normalizedData = useMemo(() => {
    const builtData = data ? build(normalize(data), 'reportedAssets') || [] : []

    return sortArrayByProperty(builtData, 'createdAt')
  }, [data])

  return {
    data: normalizedData,
    isLoading,
    totalPages: data?.meta?.last || 0,
  }
}

export default useFetchReportedPics
