import { DateTime } from 'luxon'

const dateFormats = {
  shortDateFormat: 'D',
  longDateFormat: 'DDD',
  fullDateTimeFormat: 'DDDD t',
  dateTimeFormat: 'd LLLL yyyy',
  yearFormat: 'yyyy/MM/dd'
}

export const getFormattedDateFromISO = (isoDate, format) =>
  DateTime.fromISO(isoDate).toFormat(dateFormats[format])
