import useGlobalStore from '../../store/globalStore'
import { useMutation } from '@tanstack/react-query'
import { axiosPrivate } from '../../api/axiosClient'
import { apiEndpoints } from '../../constants'

const useUpdateProfile = (handleCancel) => {
  const { user, setGlobalUser, showToastMessage } = useGlobalStore((state) => ({
    user: state.user,
    setGlobalUser: state.setGlobalUser,
    showToastMessage: state.showToastMessage,
  }))

  const { mutate, isLoading } = useMutation(
    (payload) => axiosPrivate.patch(`${apiEndpoints.updateProfile}`, payload),
    {
      onSuccess: (response) => {
        const successMessage =
          response?.data?.message || 'Successfully updated the profile'

        const name = response?.data?.attributes.name
        const attributes = user.attributes

        const updatedAttributes = {
          ...attributes,
          name,
        }

        const updateUserInfo = {
          ...user,
          attributes: updatedAttributes,
        }

        setGlobalUser(updateUserInfo)
        showToastMessage(successMessage, 'success')
        handleCancel()

      },
      onError: (error) => {
        const errorMessage =
          error?.errors?.message || 'Error occurred while updating the profile'

        showToastMessage(errorMessage, 'error')
      },
    },
  )

  return { updateProfile: mutate, isUpdatingProfile: isLoading }
}

export default useUpdateProfile
