import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import CloseIcon from '@mui/icons-material/Close'
import {
  Avatar,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useUpdateTransaction } from '../../hooks'
import { getFormattedDateFromISO } from '../../utils'
import { ArrowLeftLogo, ArrowRightLogo, CalendarLogo } from '../../assets'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 8,
  overflow: 'hidden',
  overflowY: 'auto',
}

const StyledSelect = styled(Select)`
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 12px;
`

const SaveButton = styled(Button)`
  width: 100%;
  height: 54px;
  padding: 12px 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #006ba6;
  border-radius: 50px;
  border-color: #006ba6;
  text-transform: none;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;

  &:hover {
    background: #006ba6;
    border-color: #006ba6;
    color: #ffffff;
  }

  &:focus {
    background: #006ba6;
    border-color: #006ba6;
    color: #ffffff;
  }

  &:blur {
    background: #006ba6;
    border-color: #006ba6;
    color: #ffffff;
  }
`

const CancelText = styled(Typography)`
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
`

const TransactionEditModal = ({ data, page, searchTerm, modalState, handleCloseModal }) => {
  const lastTransaction = data?.subscriptions[data?.subscriptions?.length - 1]

  const { updateTransaction } = useUpdateTransaction(
    lastTransaction?.id,
    page,
    searchTerm,
    handleCloseModal,
  )

  const [editFormData, setEditFormData] = useState({
    expiryDate: '',
    status: 'Active',
  })

  const { expiryDate, status } = editFormData

  const handleDatePickerChange = (value) => {
    const newDate = value.toISOString()
    setEditFormData({ ...editFormData, expiryDate: newDate })
  }

  const handleSelectChange = (event) => {
    const value = event.target.value
    setEditFormData({ ...editFormData, status: value })
  }

  useEffect(() => {
    const subscriptionIsActive = lastTransaction?.active
    let subscriptionStatus

    if (
      lastTransaction?.cancelledStatus &&
      lastTransaction?.cancelledDate &&
      !subscriptionIsActive
    ) {
      subscriptionStatus = 'Cancelled'
    } else {
      subscriptionStatus = 'Active'
    }

    const formattedExpiryDate = getFormattedDateFromISO(
      lastTransaction?.expiryDate,
      'yearFormat',
    )

    setEditFormData({
      expiryDate: formattedExpiryDate,
      status: subscriptionStatus,
    })
  }, [lastTransaction])

  const userImage = data?.imageSize360
  const firstNameInitial = data?.firstName[0] || ''
  const lastNameInitial = data?.lastName[0] || ''

  const initials = firstNameInitial + lastNameInitial

  const handleSaveChanges = () => {
    updateTransaction(editFormData)
  }

  return (
    <Modal
      open={modalState}
      onClose={handleCloseModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          ...style,
          width: '40%',
          height: '60%',
          padding: '20px 32px 40px',
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <h4 id="parent-modal-title">Edit Transaction</h4>
          <IconButton disableRipple onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Grid container spacing={8} style={{ height: '90%' }}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" spacing={1}>
              <Avatar
                src={userImage}
                sizes="small"
                sx={{ height: 32, width: 32 }}
              >
                {initials}
              </Avatar>
              <h4
                style={{
                  marginLeft: '20px',
                  lineHeight: 1,
                  textTransform: 'capitalize',
                  fontWeight: '500',
                  fontSize: '16px',
                }}
              >
                {data?.firstName} {data?.lastName}
              </h4>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                value={dayjs(expiryDate)}
                onChange={handleDatePickerChange}
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': { 
                    border: '1px solid #ededed',
                    borderRadius: '12px'
                  }
                }}
                format="YYYY/MM/DD"
                slots={{
                  openPickerIcon: CalendarLogo,
                  leftArrowIcon: ArrowLeftLogo,
                  rightArrowIcon: ArrowRightLogo,
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}  md={6}>
            <FormControl fullWidth>
              <InputLabel id="edit-transaction-select">Status</InputLabel>
              <StyledSelect
                id="edit-transaction-select"
                label="Status"
                value={status}
                onChange={handleSelectChange}
                inputProps={{
                  'data-testid': 'edit-transaction-select',
                }}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Cancelled">Cancelled</MenuItem>
              </StyledSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <SaveButton onClick={() => handleSaveChanges()}>
              Save Changes
            </SaveButton>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CancelText
              color="secondary.main"
              onClick={() => handleCloseModal()}
            >
              Cancel
            </CancelText>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default TransactionEditModal
