import useGlobalStore from '../../store/globalStore'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosPrivate } from '../../api/axiosClient'
import { apiEndpoints, queryKeys } from '../../constants'

const useUpdateReportedUser = (page, searchTerm, handleConfirmModalClose) => {
  const queryClient = useQueryClient()
  const showToastMessage = useGlobalStore((state) => state.showToastMessage)

  const { mutate, isLoading } = useMutation(
    (payload) => axiosPrivate.post(`${apiEndpoints.reportedUsers}`, payload),
    {
      onSuccess: (response) => {
        const successMessage =
          response?.data?.message || 'Successfully updated the reported user'

        showToastMessage(successMessage, 'success')
      },
      onError: (error) => {
        const errorMessage =
          error?.errors?.message ||
          'Error occurred while updating reported user'

        showToastMessage(errorMessage, 'error')
      },
      onSettled: () => {
        handleConfirmModalClose()
        queryClient.invalidateQueries([
          queryKeys.reportedUsers,
          page,
          searchTerm,
        ])
      },
    },
  )

  return { updateReportedUser: mutate, isUpdatingReportedUser: isLoading }
}

export default useUpdateReportedUser
