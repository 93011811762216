import { useMemo } from 'react'
import build from 'redux-object'
import normalize from 'json-api-normalizer'
import { useQuery } from '@tanstack/react-query'

import { axiosPrivate } from '../../api/axiosClient'
import { apiEndpoints, itemsPerPage, queryKeys } from '../../constants'
import { sortByReportPropertyName } from '../../utils'

const useFetchReportedCommentsAndReplies = (page, searchTerm, tabState) => {
  const { data, isLoading } = useQuery(
    [
      queryKeys[tabState ? 'reportedComments' : 'reportedCommentReplies'],
      page,
      searchTerm,
    ],
    () => {
      const searchTermAvailable = !!searchTerm

      let endpoint = `${
        apiEndpoints[tabState ? 'reportedComments' : 'reportedCommentReplies']
      }?page=${page}&limit=${itemsPerPage}`

      if (searchTermAvailable) endpoint += `&search=${searchTerm}`

      return axiosPrivate.get(endpoint)
    },
    {
      enabled: !!axiosPrivate,
    },
  )

  const normalizedData = useMemo(() => {
    const builtData = data
      ? build(normalize(data), tabState ? 'reportedComments' : 'reportedCommentReplies') || []
      : []

    return sortByReportPropertyName(builtData, 'createdAt')
  }, [data, tabState])

  return {
    data: normalizedData,
    isLoading,
    totalPages: data?.meta?.last || 0,
  }
}

export default useFetchReportedCommentsAndReplies
