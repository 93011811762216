import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import { Typography, styled, useTheme } from '@mui/material'

import { getReportedPicStatus } from '../../../utils'
import { NotAvailable } from '../../../assets'

const RemovedText = styled(Typography)`
  color: #949494;
  font-size: 16px;
  font-family: DM Sans;
`

const ReportedImagesList = ({
  imagesList,
  onImageInfoClick,
  onIgnoreClick,
  onRemoveClick,
  onWarnClick,
  onRecoverClick,
}) => {
  const theme = useTheme()
  const imageBorderRadius = theme.spacing(2)

  const getFallbackImgSrc = (e, item) => {
    e.target.src = item?.assetUrl
  }

  return imagesList.map((item) => {
    const reportStatus = Number(item.reportStatus)
    const assetStatus = Number(item.isImageReported)

    const {
      title,
      color,
      showIgnoreButton,
      showWarnButton,
      showRemoveButton,
      showRecoverButton,
    } = getReportedPicStatus(reportStatus, assetStatus)

    return (
      <Box key={item.id}>
        <ImageListItem>
          {item?.assetDeletedAt ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="100%"
              padding="16px"
              bgcolor="#E5E5E5"
              borderRadius={imageBorderRadius}
            >
              <NotAvailable />
              <Box textAlign="center">
                <RemovedText>
                  This photo has been removed by the user.
                </RemovedText>
              </Box>
            </Box>
          ) : (
            <img
              src={`${item?.assetUrlSize360}`}
              onError={(e) => getFallbackImgSrc(e, item)}
              alt={item.assets?.id}
              loading="lazy"
              style={{
                height: '100%',
                borderRadius: imageBorderRadius,
              }}
            />
          )}

          <ImageListItemBar
            title={item?.assetDeletedAt ? 'Not Available' : title}
            sx={{
              backgroundColor: `${item?.assetDeletedAt ? 'secondary.transparent' : color.transparent}`,
              borderRadius: `0 0 ${imageBorderRadius} ${imageBorderRadius}`,
            }}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255)' }}
                aria-label={`info about ${item.title}`}
                onClick={() => onImageInfoClick(item)}
              >
                <InfoIcon />
              </IconButton>
            }
          />
        </ImageListItem>

        {!item?.assetDeletedAt && (
          <Box mt={2} mb={4} gap={3} display="flex" justifyContent="center">
            {showIgnoreButton && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => onIgnoreClick(item)}
              >
                Ignore
              </Button>
            )}

            {showWarnButton && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => onWarnClick(item)}
              >
                Warn
              </Button>
            )}

            {showRemoveButton && (
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => onRemoveClick(item)}
              >
                Remove
              </Button>
            )}

            {showRecoverButton && (
              <Button
                variant="outlined"
                color="success"
                size="small"
                onClick={() => onRecoverClick(item)}
              >
                Recover
              </Button>
            )}
          </Box>
        )}
      </Box>
    )
  })
}

export default ReportedImagesList
