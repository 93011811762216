export const getReportedUserStatus = (reportStatus, userStatus) => {
  const isReportWarned = reportStatus === 10
  const isActionCompleted = reportStatus === 1
  const isReportRemoved = reportStatus === 1 && userStatus === 10
  const isReportRecovered = reportStatus === 2

  const showIgnoreButton =
    (!isActionCompleted || isReportWarned) && !isReportRecovered
  const showWarnButton =
    !isActionCompleted && !isReportWarned && !isReportRecovered
  const showBlockButton = !isActionCompleted && !isReportRecovered
  const showRecoverButton = isReportRemoved

  let statusProps = {}

  if (isActionCompleted) {
    if (isReportRemoved) {
      statusProps.title = 'Suspended'
      statusProps.color = 'error'
    } else {
      statusProps.title = 'Ignored'
      statusProps.color = 'primary'
    }
  } else if (isReportRecovered) {
    statusProps.title = 'Recovered'
    statusProps.color = 'success'
  } else if (isReportWarned) {
    statusProps.title = 'Warned'
    statusProps.color = 'warning'
  } else {
    statusProps.title = 'Pending'
    statusProps.color = 'secondary'
  }

  statusProps.showIgnoreButton = showIgnoreButton
  statusProps.showWarnButton = showWarnButton
  statusProps.showBlockButton = showBlockButton
  statusProps.showRecoverButton = showRecoverButton

  return statusProps
}

export const getReportedPicStatus = (reportStatus, assetStatus) => {
  const isReportWarned = reportStatus === 10
  const isActionCompleted = reportStatus === 1
  const isReportRemoved = reportStatus === 1 && assetStatus === 1
  const isReportRecovered = reportStatus === 2

  const showIgnoreButton =
    (!isActionCompleted || isReportWarned) && !isReportRecovered
  const showWarnButton =
    !isActionCompleted && !isReportWarned && !isReportRecovered
  const showRemoveButton = !isActionCompleted && !isReportRecovered
  const showRecoverButton = isReportRemoved

  let statusProps = {}

  if (isActionCompleted) {
    if (isReportRemoved) {
      statusProps.title = 'Removed'
      statusProps.color = 'error'
    } else {
      statusProps.title = 'Ignored'
      statusProps.color = 'primary'
    }
  } else if (isReportRecovered) {
    statusProps.title = 'Recovered'
    statusProps.color = 'success'
  } else if (isReportWarned) {
    statusProps.title = 'Warned'
    statusProps.color = 'warning'
  } else {
    statusProps.title = 'Pending'
    statusProps.color = 'secondary'
  }

  statusProps.showIgnoreButton = showIgnoreButton
  statusProps.showWarnButton = showWarnButton
  statusProps.showRemoveButton = showRemoveButton
  statusProps.showRecoverButton = showRecoverButton

  return statusProps
}

export const getPremiumUserStatus = (status) => {
  let statusProps = {}

  if (status==='Premium') {
    statusProps.title = 'Premium'
    statusProps.color = 'primary'
  } else {
    statusProps.title = 'Free'
    statusProps.color = 'success'
  }

  return statusProps;
}

export const getRegisteredUserStatus = (status, deletedAt) => {
  let statusProps = {}

  if (deletedAt) {
    statusProps.title = 'Deleted'
    statusProps.color = 'error'
    return statusProps
  }

  if (status === 1) {
    statusProps.title = 'Verified'
    statusProps.color = 'success'
  }

  if (status === 0) {
    statusProps.title = 'Unverified'
    statusProps.color = 'secondary'
  }

  if (status === 10) {
    statusProps.title = 'Suspended'
    statusProps.color = 'warning'
  }

  return statusProps
}

export const getUserReportDescription = (description, descriptionTitle) => {
  if (!description && !descriptionTitle) return ''

  if (descriptionTitle?.includes('impersonating someone else')) {
    const splitValues = descriptionTitle?.split(' ')
    const descriptionLowerCase =
      description[0]?.toLowerCase() + description?.slice(1)

    const updatedDescription = `${splitValues?.[0] || ''} ${splitValues?.[1] || ''
      } ${descriptionLowerCase || ''}`

    return updatedDescription
  }

  return description || ''
}

export const getTrimmedValueForDeletedAccount = (originalString) => {
  const splitValues = originalString.split('_')

  if (splitValues.length === 2) return splitValues[1]

  const combinedString = splitValues.reduce((prev, curr, index) => {
    if (index === 0) return prev

    return index === splitValues.length - 1 ? prev + curr : prev + curr + '_'
  }, '')

  return combinedString
}
