import { Suspense } from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'

import { routes } from '../constants'
import useGlobalStore from '../store/globalStore'
import PrivateRoutes from './PrivateRoutes'
import PublicRoutes from './PublicRoutes'
import { CircularLoader } from '../components/molecules'
import {
  UsersListPage,
  LoginPage,
  ReportedPicsPage,
  ReportedUsersPage,
  ReportedCommentsPage,
  TransactionsPage,
  SettingsPage,
} from '../pages'

const WoloAdmin = () => {
  const isAuth = useGlobalStore((state) => state.isAuth)

  return (
    <Suspense fallback={<CircularLoader height="100vh" />}>
      <Routes>
        <Route
          path="/"
          element={
            isAuth ? (
              <Navigate to={routes.registeredUsers} replace />
            ) : (
              <Navigate to={routes.login} replace />
            )
          }
        />

        <Route element={<PublicRoutes isAuth={isAuth} />}>
          <Route path={routes.login} element={<LoginPage />} />
        </Route>

        <Route path="/dashboard" element={<PrivateRoutes isAuth={isAuth} />}>
          <Route index element={<UsersListPage />} />
          <Route path={routes.registeredUsers} element={<UsersListPage />} />
          <Route path={routes.reportedPics} element={<ReportedPicsPage />} />
          <Route path={routes.reportedUsers} element={<ReportedUsersPage />} />
          <Route path={routes.reportedComments} element={<ReportedCommentsPage />} />
          <Route path={routes.userSubscriptions} element={<TransactionsPage />} />
          <Route path={routes.settings} element={<SettingsPage />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default WoloAdmin
