import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table'
import { CircularLoader } from '../molecules'
import styled from '@emotion/styled'

const StyledTableCell = styled(TableCell)`
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
`

const PaginationTable = ({
  columns,
  data,
  isLoading,
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Paper variant="outlined" sx={{ width: '100%', overflow: 'hidden' }}>
      {isLoading ? (
        <CircularLoader height="80vh" />
      ) : (
        <Table aria-label="sticky table" stickyHeader>
          <>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const columnsDef = header.column.columnDef

                    const cellWidth = columnsDef?.width
                    const cellMinWidth = columnsDef?.minWidth
                    const cellMaxWidth = columnsDef?.maxWidth
                    return (
                      <TableCell
                        key={header.id}
                        colSpan={header.colSpan}
                        sx={{
                          py: 2,
                          ...(cellWidth &&
                            cellMaxWidth && {
                              width: cellWidth,
                              minWidth: cellMinWidth,
                              maxWidth: cellMaxWidth,
                            }),
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(columnsDef.header, header.getContext())}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableHead>

            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <StyledTableCell
                        key={cell.id}
                        sx={{ py: 2 }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </StyledTableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </>
        </Table>
      )}
    </Paper>
  )
}

export default PaginationTable
