import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { ModalWrapper, Pill } from '../../atoms'
import { LabelValuePair } from '../../molecules'
import {
  getFormattedDateFromISO,
  getReportedUserStatus,
  getUserReportDescription,
} from '../../../utils'

const ReportedUserDetailsModal = ({
  isUserDetailsModalOpen,
  handleCloseUserDetailsModal,
  currentSelectedUser,
}) => {
  const LastReportedOn = getFormattedDateFromISO(
    currentSelectedUser?.createdAt,
    'fullDateTimeFormat',
  )

  const ownerFullName =
    currentSelectedUser?.owner.firstName +
    ' ' +
    currentSelectedUser?.owner.lastName

  const reports = currentSelectedUser?.reports || []

  const reportStatus = Number(currentSelectedUser?.reportStatus)
  const userStatus = Number(currentSelectedUser?.userStatus)

  const { title, color } = getReportedUserStatus(reportStatus, userStatus)

  return (
    <ModalWrapper
      width="40vw"
      title={ownerFullName}
      showCloseIcon
      isOpen={isUserDetailsModalOpen}
      onClose={handleCloseUserDetailsModal}
    >
      {isUserDetailsModalOpen && currentSelectedUser && (
        <>
          <Box>
            <Typography sx={{ mt: -2, mb: 4 }} color="secondary.main">
              {currentSelectedUser.owner.email || ''}
            </Typography>

            <LabelValuePair
              label="Last Reported On"
              value={LastReportedOn || ''}
            />

            <LabelValuePair
              label="Status"
              value={<Pill label={title} colorCode={color} />}
            />

            <Box>
              <Typography
                color="secondary.main"
                mb={1}
                sx={{ textTransform: 'uppercase' }}
              >
                Reports
              </Typography>

              {reports.map((report) => {
                const reportCounts = report?.reporters?.length
                const isMultipleReports = report?.reporters?.length > 1

                const reportDescription = getUserReportDescription(
                  report.description,
                  report.descriptionTitle,
                )

                return (
                  <Box key={report.description} width="100%" mb={2}>
                    <Typography noWrap sx={{ whiteSpace: 'normal' }}>
                      {reportDescription}{' '}
                      {isMultipleReports && (
                        <Typography component="span" variant="body2">
                          ({reportCounts})
                        </Typography>
                      )}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </>
      )}
    </ModalWrapper>
  )
}

export default ReportedUserDetailsModal
