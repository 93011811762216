import { useEffect, useState } from 'react'

const usePageFilter = (delay) => {
  const [userType, setUserType] = useState('all')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedValue, setDebouncedValue] = useState(searchTerm)

  useEffect(() => {
    const updateTimer = setTimeout(() => {
      setDebouncedValue(searchTerm)
      //   When search term updated, change to 1st page
      setCurrentPage(1)
    }, delay)

    return () => clearTimeout(updateTimer)
  }, [searchTerm, delay])

  return {
    currentPage,
    searchTerm,
    userType,
    startDate,
    endDate,
    debouncedSearchTerm: debouncedValue,
    setCurrentPage,
    setSearchTerm,
    setUserType,
    setStartDate,
    setEndDate
  }
}

export default usePageFilter
