import { styled } from '@mui/material/styles'

export default styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'space-between' : 'flex-end',
  padding: theme.spacing(1, 4),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))
