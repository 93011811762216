import { Component } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { PrimaryButton } from '../atoms'

const wrapperStyle = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const innerBoxStyle = {
  width: '50%',
  height: '40%',
  border: '2px solid #EDEDED',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '5px 5px 20px #E9EBEC',
}

const textStyle = {
  margin: 0,
  fontFamily: 'DM Sans',
  paddingBottom: '20px',
}

const buttonStyle = {
  fontFamily: 'DM Sans',
}

const SomethingWentWrong = () => {
  const handleReloadPage = () => {
    window.location.reload()
  }

  return (
    <div style={wrapperStyle}>
      <div style={innerBoxStyle}>
        <InfoIcon sx={{ fontSize: '60px', pb: 3, color: '#FF8181' }} />
        <p style={textStyle}>
          Something went wrong. Please reload the page and try again.
        </p>
        <PrimaryButton onClick={handleReloadPage} style={buttonStyle}>
          Reload
        </PrimaryButton>
      </div>
    </div>
  )
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return <SomethingWentWrong />
    }

    return this.props.children
  }
}

export default ErrorBoundary
