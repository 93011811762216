import useGlobalStore from '../../store/globalStore'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosPrivate } from '../../api/axiosClient'
import { apiEndpoints, queryKeys } from '../../constants'

const useRecoverBlockedCommentAndReply = (
  page,
  searchTerm,
  handleConfirmModalClose,
  tabState,
) => {
  const queryClient = useQueryClient()
  const showToastMessage = useGlobalStore((state) => state.showToastMessage)

  const { mutate, isLoading } = useMutation(
    (commentOrReplyId) =>
      axiosPrivate.post(
        `${
          apiEndpoints[tabState ? 'recoverComment' : 'recoverCommentReply']
        }/${commentOrReplyId}`,
      ),
    {
      onSuccess: (response) => {
        const successMessage =
          response?.data?.message ||
          `Successfully recovered the reported ${
            tabState ? 'comment' : 'comment reply'
          }`

        showToastMessage(successMessage, 'success')
      },
      onError: (error) => {
        const errorMessage =
          error?.errors?.message ||
          `Error occurred while recovering reported ${
            tabState ? 'comment' : 'comment reply'
          }`

        showToastMessage(errorMessage, 'error')
      },
      onSettled: () => {
        handleConfirmModalClose()
        queryClient.invalidateQueries([
          queryKeys[tabState ? 'reportedComments' : 'reportedCommentReplies'],
          page,
          searchTerm,
        ])
      },
    },
  )

  return {
    recoverBlockedCommentAndReply: mutate,
    isRecoveringBlockedCommentAndReply: isLoading,
  }
}

export default useRecoverBlockedCommentAndReply
