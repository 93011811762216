import { useState } from 'react'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const DropMenu = ({ menuItems, disableMenu }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = !!anchorEl

  const handleIconClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (actionHandler) => {
    actionHandler()
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        sx={{
          '& .Mui-disabled': {
            pointerEvents: 'auto',
            cursor: 'not-allowed',
          },
        }}
      >
        <IconButton
          id="menu-button"
          onClick={handleIconClick}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disabled={disableMenu}
        >
          <MoreVertIcon
            color={disableMenu ? 'secondary' : 'primary'}
            sx={{ fontSize: '1.125rem' }}
          />
        </IconButton>
      </Box>

      {menuItems && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'menu-button',
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {menuItems.map(({ label, actionHandler }) => {
            return (
              <MenuItem
                key={label}
                onClick={() => handleMenuItemClick(actionHandler)}
                sx={{ px: 8, fontSize: '0.875rem' }}
              >
                {label}
              </MenuItem>
            )
          })}
        </Menu>
      )}
    </>
  )
}

export default DropMenu
