import { useState } from 'react'

const useConfirmationModal = () => {
  const [isConfirmModalOpen, setIsConfirmModal] = useState(false)
  const [confirmModalProps, setConfirmModalProps] = useState({
    title: 'Are you sure?',
    description: 'You are about to confirm this action.',
    showConfirmButton: true,
    confirmButtonLabel: 'Confirm',
    confirmButtonColor: 'success',
    showCancelButton: true,
    cancelButtonLabel: 'Cancel',
    cancelButtonColor: 'error',
    handleConfirmAction: () => {},
    handleCancelAction: () => setIsConfirmModal(false),
  })

  const handleConfirmModalOpen = () => setIsConfirmModal(true)
  const handleConfirmModalClose = () => setIsConfirmModal(false)

  const updateConfirmModalProps = (updatedProps) => {
    setConfirmModalProps((prev) => ({ ...prev, ...updatedProps }))
  }

  return {
    isConfirmModalOpen,
    handleConfirmModalOpen,
    handleConfirmModalClose,
    confirmModalProps,
    updateConfirmModalProps,
  }
}

export default useConfirmationModal
