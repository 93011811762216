import {
  Box,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useEffect, useState } from 'react'

import { CreateNewPasswordImg, ForgotPasswordImg } from '../../../assets'
import ActionButton from '../../atoms/ActionButton'
import {
  CancelText,
  ModalSubTitle,
  ModalTitle,
  OTPInput,
  ResendText,
} from './styles'
import { apiEndpoints } from '../../../constants'
import { getErrorMessage } from '../../../utils'
import useGlobalStore from '../../../store/globalStore'
import { axios } from '../../../api/axiosClient'

const ForgotPasswordModal = ({
  modalState,
  setModalState,
  handleModalClose,
}) => {
  const [stepState, setStepState] = useState(1)

  const [forgotEmail, setForgotEmail] = useState('')
  const [forgotEmailRequired, setForgotEmailRequired] = useState(false)
  const [emailValidationError, setEmailValidationError] = useState(false)

  const [otp, setOtp] = useState(['', '', '', ''])
  const [confirmCodeDisabled, setConfirmCodeDisabled] = useState(true)

  const [forgotPasswordData, setForgotPasswordData] = useState({
    newPassword: '',
    confirmPassword: '',
  })

  const { newPassword, confirmPassword } = forgotPasswordData

  const [forgotPasswordErrors, setForgotPasswordErrors] = useState({
    newPassword: false,
    confirmPassword: false,
  })

  const {
    newPassword: newPasswordRequired,
    confirmPassword: confirmPasswordRequired,
  } = forgotPasswordErrors

  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  })

  const { newPassword: showNewPassword, confirmPassword: showConfirmPassword } =
    showPassword

  const [passwordValidationError, setPasswordValidationError] = useState(false)
  const [confirmError, setConfirmError] = useState(false)

  const { showToastMessage } = useGlobalStore((state) => ({
    showToastMessage: state.showToastMessage,
  }))

  const [timeLeft, setTimeLeft] = useState(120)
  const [isTimerRunning, setIsTimerRunning] = useState(false)
  const [token, setToken] = useState('')

  useEffect(() => {
    let timer
    const decrementTimer = () => {
      setTimeLeft((prevTime) => prevTime - 1)
    }
    if (isTimerRunning && timeLeft > 0) {
      timer = setInterval(decrementTimer, 1000)
    } else if (timeLeft === 0) {
      clearInterval(timer)
    }
    return () => clearInterval(timer)
  }, [isTimerRunning, timeLeft])

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '560px',
    height: `${stepState === 3 ? '95vh' : '85vh'}`,
    padding: '32px',
    bgcolor: '#fff',
    boxShadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.10)',
    borderRadius: '8px',
  }

  const handleModalTitle = () => {
    switch (stepState) {
      case 3:
        return 'Create new password'

      case 2:
        return 'Enter your code'

      default:
        return 'Enter your email'
    }
  }

  const handleModalSubTitle = () => {
    switch (stepState) {
      case 3:
        return 'Your new password must be different from the previous one.'

      case 2:
        return 'We have sent 4 digit code to your email. Use that code to change your password.'

      default:
        return '4 digit code will be sent to your. Use that code to change your password.'
    }
  }

  const handleModalButtonText = () => {
    switch (stepState) {
      case 3:
        return 'Change password'

      case 2:
        return 'Confirm'

      default:
        return 'Send code'
    }
  }

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(email)
  }

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*\W)(?!.*\s).{8,}$/
    return regex.test(password)
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setForgotEmail(value)
  }

  const handleRequestPasswordReset = async () => {
    try {
      const { data, status } = await axios.post(
        apiEndpoints.requestResetPassword,
        {
          email: forgotEmail,
        },
      )
      if (status === 200) {
        showToastMessage(
          data?.data?.message || 'Your OTP has been sent to your email.',
          'success',
        )
        setStepState(2)
        setIsTimerRunning(true)
      }
    } catch (err) {
      const errorMessage = getErrorMessage(err)
      showToastMessage(errorMessage, 'error')
    }
  }

  const handleSendCode = () => {
    setForgotEmailRequired(forgotEmail.trim() === '')

    if (!forgotEmail) {
      return
    }

    if (!validateEmail(forgotEmail)) {
      setEmailValidationError(true)
      return
    }
    setEmailValidationError(false)

    handleRequestPasswordReset()
  }

  const handleOTPChange = (element, index) => {
    if (isNaN(element.value)) return false

    setOtp([...otp.map((item, idx) => (idx === index ? element.value : item))])

    // focus next input

    if (element.nextSibling) {
      element.nextSibling.focus()
    }
  }

  const handleResendCode = async () => {
    setTimeLeft(120)
    setIsTimerRunning(true)

    try {
      const { data, status } = await axios.post(
        apiEndpoints.requestResetPassword,
        {
          email: forgotEmail,
        },
      )

      if (status === 200) {
        showToastMessage(
          data?.data?.message || 'Your OTP has been sent to your email.',
          'success',
        )
      }
    } catch (err) {
      const errorMessage = getErrorMessage(err)
      showToastMessage(errorMessage, 'error')
    }
  }

  useEffect(() => {
    const code = otp.join('')
    if (code.length !== 4) {
      setConfirmCodeDisabled(true)
    } else {
      setConfirmCodeDisabled(false)
    }
  }, [otp])

  const handleConfirmCode = async () => {
    try {
      const code = otp.join('')
      const { data, status } = await axios.post(apiEndpoints.verifyOTP, {
        email: forgotEmail,
        otp: code,
      })
      if (status === 200) {
        const token = data?.data?.token
        setToken(token)
        setStepState(3)
        setIsTimerRunning(false)
        setTimeLeft(120)
      }
    } catch (err) {
      const errorMessage = getErrorMessage(err)
      showToastMessage(errorMessage, 'error')
    }
  }

  const handlePasswordInputChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setForgotPasswordData({ ...forgotPasswordData, [name]: value })
    setForgotPasswordErrors({
      ...forgotPasswordErrors,
      [name]: value.trim() === '',
    })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleClickShowPassword = (e, inputName) => {
    e.stopPropagation()
    setShowPassword((show) => {
      return { ...showPassword, [inputName]: !show[inputName] }
    })
  }

  const handleResetPassword = async () => {
    try {
      const { data, status } = await axios.post(
        `${apiEndpoints.resetPassword}/${token}`,
        {
          password: newPassword,
          repeatPassword: confirmPassword,
        },
      )
      if (status === 200) {
        showToastMessage(
          data?.success?.message ||
            'Your password has been reset successfully.',
          'success',
        )
        handleCancelClick()
      }
    } catch (err) {
      const errorMessage = getErrorMessage(err)
      showToastMessage(errorMessage, 'error')
    }
  }

  const handleChangePassword = () => {
    const newErrors = {
      newPassword: newPassword.trim() === '',
      confirmPassword: confirmPassword.trim() === '',
    }

    setForgotPasswordErrors(newErrors)

    if (
      newErrors.currentPassword ||
      newErrors.newPassword ||
      newErrors.confirmPassword
    ) {
      return
    }

    if (!validatePassword(newPassword)) {
      setPasswordValidationError(true)
      return
    }
    setPasswordValidationError(false)

    if (newPassword !== confirmPassword) {
      setConfirmError(true)
      return
    }

    setConfirmError(false)
    handleResetPassword()
  }

  const handleModalButtonClick = () => {
    switch (stepState) {
      case 3:
        handleChangePassword()
        break

      case 2:
        handleConfirmCode()
        break

      default:
        handleSendCode()
    }
  }

  const handleCancelClick = () => {
    setStepState(1)
    setForgotEmail('')
    setForgotEmailRequired(false)
    setEmailValidationError(false)

    setOtp(['', '', '', ''])
    setConfirmCodeDisabled(true)

    setForgotPasswordData({
      newPassword: '',
      confirmPassword: '',
    })
    setForgotPasswordErrors({
      newPassword: false,
      confirmPassword: false,
    })
    setShowPassword({
      newPassword: false,
      confirmPassword: false,
    })
    setPasswordValidationError(false)
    setConfirmError(false)

    setTimeLeft(120)
    setIsTimerRunning(false)
    setToken('')

    handleModalClose()
  }

  return (
    <Modal
      open={modalState}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Box display="flex" justifyContent="center" alignItems="center">
          {stepState !== 3 ? <ForgotPasswordImg /> : <CreateNewPasswordImg />}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <ModalTitle>{handleModalTitle()}</ModalTitle>
          <ModalSubTitle>{handleModalSubTitle()}</ModalSubTitle>
          {stepState === 1 && (
            <TextField
              required
              error={forgotEmailRequired || emailValidationError}
              helperText={
                forgotEmailRequired
                  ? 'Email is required'
                  : emailValidationError
                  ? 'Please enter a valid email address'
                  : ''
              }
              value={forgotEmail}
              onChange={handleInputChange}
              label="Email"
              size="normal"
              sx={{
                width: '100%',
                marginTop: '24px',
                // Decrease input field height
                '& .MuiInputBase-root': { borderRadius: '12px' },
              }}
              inputProps={{
                'data-testid': 'login-forgot_password-email',
              }}
            />
          )}

          {stepState === 2 && (
            <>
              <Box marginTop="24px">
                {otp.map((data, index) => (
                  <OTPInput
                    type="text"
                    name="otp"
                    maxLength="1"
                    key={index}
                    value={data}
                    onChange={(e) => handleOTPChange(e.target, index)}
                    onFocus={(e) => e.target.select()}
                    idx={index}
                  />
                ))}
              </Box>
              <Box display="flex" marginTop="24px">
                {formatTime(timeLeft)}
                <ResendText
                  onClick={() => handleResendCode()}
                  disabled={timeLeft !== 0}
                >
                  Resend code
                </ResendText>
              </Box>
            </>
            // 4 code input here
          )}

          {stepState === 3 && (
            // new password and confirm password input here
            <Box>
              <TextField
                required
                error={newPasswordRequired || passwordValidationError}
                helperText={
                  newPasswordRequired
                    ? 'New password is required'
                    : passwordValidationError
                    ? 'Password must be atleast 8 characters and contain a number, an uppercase letter, a special character and no spaces'
                    : ''
                }
                value={newPassword}
                onChange={handlePasswordInputChange}
                name="newPassword"
                label="New Password"
                size="normal"
                type={showNewPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) =>
                          handleClickShowPassword(e, 'newPassword')
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: '100%',
                  marginTop: '24px',
                  // Decrease input field height
                  '& .MuiInputBase-input': { height: '1.4em' },
                  '& .MuiInputBase-root': { borderRadius: '12px' },
                }}
                inputProps={{
                  'data-testid': 'login-forgot_password-input-new_password',
                }}
              />
              <TextField
                required
                error={confirmPasswordRequired || confirmError}
                helperText={
                  confirmPasswordRequired
                    ? 'Confirm password is required'
                    : confirmError
                    ? 'Confirm Password must match the new password'
                    : ''
                }
                value={confirmPassword}
                onChange={handlePasswordInputChange}
                name="confirmPassword"
                label="Confirm Password"
                size="normal"
                type={showConfirmPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) =>
                          handleClickShowPassword(e, 'confirmPassword')
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: '100%',
                  marginTop: '24px',
                  // Decrease input field height
                  '& .MuiInputBase-input': { height: '1.4em' },
                  '& .MuiInputBase-root': { borderRadius: '12px' },
                }}
                inputProps={{
                  'data-testid': 'login-forgot_password-input-confirm_password',
                }}
              />
            </Box>
          )}

          <ActionButton
            marginTop="24px"
            onClick={() => handleModalButtonClick()}
            disabled={stepState === 2 && confirmCodeDisabled}
          >
            {handleModalButtonText()}
          </ActionButton>
          <CancelText onClick={() => handleCancelClick()}>Cancel</CancelText>
        </Box>
      </Box>
    </Modal>
  )
}

export default ForgotPasswordModal
