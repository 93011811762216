import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { axiosPrivate } from '../../api/axiosClient'
import { apiEndpoints, itemsPerPage, queryKeys } from '../../constants'
import { getTrimmedValueForDeletedAccount } from '../../utils'

const useFetchSubscribedUsers = (page, searchTerm, startDate, endDate) => {
  const { data, isLoading } = useQuery(
    [queryKeys.allSubscribedUsers, page, searchTerm, startDate, endDate],
    () => {
      const searchTermAvailable = !!searchTerm
      const startDateAvailable = !!startDate
      const endDateAvailable = !!endDate

      let endpoint = `${apiEndpoints.subscribedUsers}?page=${page}&limit=${itemsPerPage}`

      if (searchTermAvailable) endpoint += `&search=${searchTerm}`
      if (startDateAvailable && endDateAvailable) endpoint += `&startDate=${startDate}&endDate=${endDate}`

      return axiosPrivate.get(endpoint)
    },
    {
      enabled: !!axiosPrivate,
    },
  )

  const subscribedUsersList = useMemo(() => {
    return data
      ? data?.data?.map((userInfo) => {
        const email = userInfo.attributes?.email
        const username = userInfo.attributes?.username
        const isAccountDeleted = !!userInfo.attributes?.deletedAt

        const userEmail = isAccountDeleted
          ? getTrimmedValueForDeletedAccount(email)
          : email
        const updatedUsername = isAccountDeleted
          ? getTrimmedValueForDeletedAccount(username)
          : username

        const updatedUserInfo = {
          ...userInfo,
          ...userInfo.attributes,
          email: userEmail,
          username: updatedUsername,
        }
        delete updatedUserInfo.attributes

        return updatedUserInfo
      }) || []
      : []
  }, [data])

  return {
    data: subscribedUsersList,
    isLoading,
    totalPages: data?.meta?.last || 0,
  }
}

export default useFetchSubscribedUsers
