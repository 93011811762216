import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const PageTitle = ({ title, width }) => {
  return (
    <Box display="flex" alignItems="center" width={width}>
      <Typography variant="h5">
        <strong>{title}</strong>
      </Typography>
    </Box>
  )
}

export default PageTitle
