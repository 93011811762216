import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import InfoIcon from '@mui/icons-material/Info'
import Typography from '@mui/material/Typography'
import { ModalWrapper } from '../atoms'

const ConfirmationModal = ({
  title,
  description,
  showConfirmButton,
  confirmButtonLabel,
  confirmButtonColor,
  handleConfirmAction,
  showCancelButton,
  cancelButtonLabel,
  cancelButtonColor,
  handleCancelAction,
  isConfirmModalOpen,
  handleConfirmModalClose,
  isLoading,
}) => {
  return (
    <ModalWrapper
      isOpen={isConfirmModalOpen}
      onClose={handleConfirmModalClose}
      sxProps={{ width: 'max-content', maxWidth: '30vw' }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <InfoIcon sx={{ fontSize: '50px', color: 'warning.main' }} />
        <Typography data-testid="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography
          data-testid="modal-modal-description"
          sx={{ mt: 2 }}
          align="center"
        >
          {description}
        </Typography>
      </Box>

      <Box mt={4} display="flex" justifyContent="space-between">
        {showCancelButton && (
          <Button
            variant="text"
            color={cancelButtonColor}
            size="small"
            onClick={handleCancelAction}
            disabled={isLoading}
          >
            {cancelButtonLabel}
          </Button>
        )}

        {showConfirmButton && (
          <Button
            variant="text"
            color={confirmButtonColor}
            size="small"
            onClick={handleConfirmAction}
            disabled={isLoading}
          >
            {confirmButtonLabel}
          </Button>
        )}
      </Box>
    </ModalWrapper>
  )
}

export default ConfirmationModal
