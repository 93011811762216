import { useState } from 'react'
import Box from '@mui/material/Box'

import { AppHeader, Sidebar, SidebarHeader } from '../molecules'

const DashboardWrapper = ({ children }) => {
  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppHeader {...{ open, handleDrawerOpen }} />

      <Sidebar {...{ open, handleDrawerClose }} />

      <Box component="main" sx={{ flexGrow: 1, p: 6 }}>
        <SidebarHeader />
        {children}
      </Box>
    </Box>
  )
}

export default DashboardWrapper
