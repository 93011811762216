import { createTheme, responsiveFontSizes } from '@mui/material'

const theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: '#006ba6',
      light: '#78B7DC',
      extraLight: '#D0EBF6',
      transparent: '#006BA6EB',
      grey: '#808080',
    },
    secondary: {
      main: '#696969',
      light: '#EDEDED',
      extraLight: '#E9EBEC',
      transparent: '#9C9E9FEB',
    },
    success: {
      main: '#469697',
      light: '#7DC4A6',
      extraLight: '#C9F3DC',
      dark: '#167E70',
      transparent: '#469697EB',
    },
    error: {
      main: '#CE542D',
      light: '#FF8181',
      extraLight: '#F5D6D6',
      transparent: '#CE542DEB',
    },
    warning: {
      main: '#76561E',
      light: '#FFEED0',
      extraLight: '#F9F0C4',
      transparent: '#AB870AEB',
    },
    grey: {
      main: '#A6A6A6',
    },
  },
  typography: {
    fontFamily: ['DM Sans', 'sans-serif'].join(', '),
  },
})

export default responsiveFontSizes(theme)
