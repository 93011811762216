import * as Yup from 'yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import CircularProgress from '@mui/material/CircularProgress'

import { axios } from '../../api/axiosClient'
import { ControlledInput, PrimaryButton } from '../atoms'
import { apiEndpoints } from '../../constants'
import useGlobalStore from '../../store/globalStore'
import { getErrorMessage, setItem } from '../../utils'
import { ForgotPasswordModal } from './forgotPassword'

const loginFormResolver = Yup.object().shape({
  email: Yup.string().email('Invalid Email').required('Required'),
  password: Yup.string().required('Required'),
})

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [modalState, setModalState] = useState(false)

  const { setAuth, setGlobalUser, showToastMessage } = useGlobalStore(
    (state) => ({
      setAuth: state.setAuth,
      setGlobalUser: state.setGlobalUser,
      setAuthTokens: state.setAuthTokens,
      showToastMessage: state.showToastMessage,
    }),
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(loginFormResolver),
  })

  const handleLoginFormSubmit = handleSubmit(async (formData) => {
    const { email, password } = formData

    try {
      setIsLoading(true)
      const response = await axios.post(apiEndpoints.login, {
        email: email,
        password: password,
      })

      const userInfo = response.data?.data
      const userToken = userInfo?.attributes?.token
      const refreshToken = userInfo?.attributes?.refreshToken
      const isAuth = userToken && refreshToken

      setGlobalUser(userInfo)
      setAuth(isAuth)
      setItem('userToken', userToken)
      setItem('refreshToken', refreshToken)
    } catch (err) {
      const errorMessage = getErrorMessage(err)
      showToastMessage(errorMessage, 'error')
    } finally {
      setIsLoading(false)
    }
  })

  const handleModalOpen = () => setModalState(true)

  const handleModalClose = () => setModalState(false)

  return (
    <Box width="60%">
      <Box pb={5}>
        <Typography variant="h5" color="success.main">
          Welcome Back
        </Typography>
        <Typography color="success.light">
          Please enter your email and password to sign in.
        </Typography>
      </Box>

      <form>
        <Box>
          <ControlledInput
            name="email"
            testId="login-email-input"
            control={control}
            id="email"
            label="Email"
            margin="normal"
            size="small"
            required
            fullWidth
            error={!!errors?.email}
            helperText={errors?.email?.message}
          />
          <ControlledInput
            name="password"
            testId="login-password-input"
            control={control}
            id="password"
            type="password"
            label="Password"
            margin="normal"
            size="small"
            required
            fullWidth
            error={!!errors?.password}
            helperText={errors?.password?.message}
          />
        </Box>

        <Box style={{ cursor: 'pointer' }}>
          <Typography color="primary.grey" onClick={() => handleModalOpen()}>
            Forgot password?
          </Typography>
        </Box>

        <Box mt={5}>
          <PrimaryButton
            fullWidth
            size="large"
            type="submit"
            disabled={isLoading}
            onClick={handleLoginFormSubmit}
          >
            {isLoading ? <CircularProgress size={26.25} /> : 'Sign In'}
          </PrimaryButton>
        </Box>
      </form>
      <ForgotPasswordModal
        modalState={modalState}
        setModalState={setModalState}
        handleModalClose={handleModalClose}
      />
    </Box>
  )
}

export default LoginForm
