import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import useGlobalStore from '../../store/globalStore'
import {
  getErrorMessage,
  getFormattedDateFromISO,
  getRegisteredUserStatus,
  getTrimmedValueForDeletedAccount,
} from '../../utils'
import { axiosPrivate } from '../../api/axiosClient'
import { apiEndpoints, queryKeys } from '../../constants'

const useFetchAllSubscribedUsers = () => {
  const showToastMessage = useGlobalStore((state) => state.showToastMessage)

  const { data, isFetching, isSuccess, refetch } = useQuery(
    [queryKeys.allSubscribedUsers],
    () => axiosPrivate.get(apiEndpoints.subscribedUsers),
    {
      // Only call endpoint when refetch method is called
      enabled: false,
      onError: (error) => {
        const errorMessage = getErrorMessage(error)
        showToastMessage(errorMessage, 'error')
      },
    },
  )

  const subscribedUsersList = useMemo(() => {
    return data
      ? data?.data?.map((userInfo, index) => {
        const email = userInfo.attributes?.email
        const username = userInfo.attributes?.username
        const isAccountDeleted = !!userInfo.attributes?.deletedAt

        const { title } = getRegisteredUserStatus(
          userInfo.attributes?.status,
          userInfo.attributes?.deletedAt,
        )

        const userEmail = isAccountDeleted
          ? getTrimmedValueForDeletedAccount(email)
          : email
        const updatedUsername = isAccountDeleted
          ? getTrimmedValueForDeletedAccount(username)
          : username

        const activeSubscription = userInfo.attributes?.subscriptions.filter(subscription => subscription.active === true)
        const packageInfo = activeSubscription[0].packageInfo

        let status;
        const today = new Date();
        const expiryDate = new Date(activeSubscription[0].expiryDate);
        if (activeSubscription[0].cancelledStatus && activeSubscription[0].cancelledStatus === true) {
          status = "Canceled"
        }else if(today > expiryDate) {
          status = "Expired"
        }else{
          status = "Active"
        }

        const updatedUserInfo = {
          ...userInfo,
          serialNo: index + 1,
          registeredOn: getFormattedDateFromISO(
            userInfo.attributes?.createdAt,
            'longDateFormat',
          ),
          ...userInfo.attributes,
          amount: packageInfo.includes("1m") ? "$0.99" : "$6.99",
          subscription: packageInfo.includes("1m") ? "Monthly" : "Yearly",
          userStatus: title,
          email: userEmail,
          username: updatedUsername,
          startDate: getFormattedDateFromISO(activeSubscription[0].createdAt, 'dateTimeFormat'),
          expiryDate: getFormattedDateFromISO(activeSubscription[0].expiryDate, 'dateTimeFormat'),
          status
        }

        delete updatedUserInfo.attributes

        return updatedUserInfo
      }) || []
      : []
  }, [data])

  return {
    allSubscribedUsersList: subscribedUsersList,
    isFetchingAllSubscribedUsers: isFetching,
    fetchAllSubscribedUsers: refetch,
    isFetchAllSubscribedUsersSuccess: isSuccess,
  }
}

export default useFetchAllSubscribedUsers
