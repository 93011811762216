import { Box } from '@mui/material'
import { AppLogo } from '../../assets'

const FixedLogo = () => {
  return (
    <Box sx={{ position: 'fixed', left: 40, top: 20 }}>
      <AppLogo width="60px" height="60px" />
    </Box>
  )
}

export default FixedLogo
