import React from 'react'
import Button from '@mui/material/Button'

const PrimaryButton = ({ children, ...rest }) => {
  return (
    <Button
      variant="contained"
      sx={{
        background:
          'linear-gradient(114.83deg, #669BBA 16.72%, #78B7DC 84.18%)',
      }}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default PrimaryButton
