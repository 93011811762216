import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

const CircularLoader = ({ height }) => {
  return (
    <Box
      sx={{ display: 'flex' }}
      width="100%"
      height={height}
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  )
}

export default CircularLoader
