import React from 'react'
import Button from '@mui/material/Button'

const ActionButton = ({ children, marginTop, ...rest }) => {
  return (
    <Button
      variant="contained"
      sx={{
        background: '#006BA6',
        marginTop,
        width: '350px',
        height: '55px',
        borderRadius: '50px',
        textTransform: 'none',
        fontSize: '18px',
        fontWeight: 700,
      }}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default ActionButton
