import React from 'react'
import { TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

// Hack to remove bgcolor when input is autofilled
const inputStyle = { WebkitBoxShadow: '0 0 0 1000px white inset' }

const ControlledInput = ({ name, control, testId, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          inputProps={{ style: inputStyle, 'data-testid': testId }}
          {...field}
          {...rest}
        />
      )}
    />
  )
}

export default ControlledInput
