import axiosClient from 'axios'
import useGlobalStore from '../store/globalStore'
import { clearStorage, getItem, setItem } from '../utils'

const axios = axiosClient.create({
  baseURL: process.env.REACT_APP_API_ROOT_URL,
  timeout: 25000,
})

const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT_URL,
  headers: { 'Content-Type': 'application/json' },
  timeout: 25000,
})

const clearStorageAndLogout = () => {
  useGlobalStore.getState().clearStore()
  useGlobalStore.getState().showToastMessage('Session Expired', 'error')
  clearStorage()
}

const refreshAuthTokens = async () => {
  const refreshToken = getItem('refreshToken')

  try {
    const refreshResponse = await axios.post('/auth/token', {
      refreshToken,
    })

    const data = refreshResponse?.data?.data
    return data
  } catch (err) {
    clearStorageAndLogout()
  }
}

axiosPrivate.interceptors.request.use((config) => {
  if (!config.headers?.['Authorization']) {
    const userToken = getItem('userToken')

    if (!userToken) {
      clearStorageAndLogout()
    }

    config.headers['Authorization'] = `Bearer ${userToken}`
  }
  return config
})

axiosPrivate.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data)
  },
  async (error) => {
    const prevRequest = error?.config

    if (error?.response?.status === 401 && !prevRequest.sent) {
      prevRequest.sent = true
      const { token: newToken } = await refreshAuthTokens()

      setItem('userToken', newToken)
      useGlobalStore.getState().setUserToken(newToken)

      prevRequest.headers['Authorization'] = `Bearer ${newToken}`
      return axiosPrivate(prevRequest)
    } else {
      return Promise.reject(error?.response?.data)
    }
  },
)

export { axios, axiosPrivate }
