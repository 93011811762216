import create from 'zustand'
import { persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

const defaultStateValues = {
  user: {},
  isAuth: false,
  axiosInstance: null,
  toastMessageProps: {
    isOpen: false,
    message: '',
    variant: 'success',
  },
}

const store = immer((set) => ({
  ...defaultStateValues,
  setAuth: () => set((state) => ({ ...state, isAuth: true })),
  setGlobalUser: (userInfo) => set((state) => ({ ...state, user: userInfo })),
  setAuthTokens: (updatedTokens) =>
    set((state) => ({ ...state, authTokens: updatedTokens })),
  setUserToken: (userToken) =>
    set((state) => ({
      ...state,
      authTokens: { ...state.authTokens, userToken },
    })),
  showToastMessage: (message, variant) =>
    set((state) => ({
      ...state,
      toastMessageProps: { isOpen: true, message, variant },
    })),
  closeToastMessage: () =>
    set((state) => ({
      ...state,
      toastMessageProps: { ...state.toastMessageProps, isOpen: false },
    })),
  clearStore: () => set(() => defaultStateValues),
}))

const persistedStore = persist(store, {
  name: 'global-state',
  // Only persist the following keys
  partialize: (state) => ({
    user: state.user,
    isAuth: state.isAuth,
  }),
})

const useGlobalStore = create(persistedStore)

export default useGlobalStore
