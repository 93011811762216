import { forwardRef } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import useGlobalStore from '../../store/globalStore'

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const ToastMessage = () => {
  const toastMessageProps = useGlobalStore((state) => state.toastMessageProps)
  const closeToastMessage = useGlobalStore((state) => state.closeToastMessage)

  const { isOpen, message, variant } = toastMessageProps

  const onClose = (_, reason) => {
    if (reason === 'clickaway') return

    closeToastMessage()
  }

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={variant} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default ToastMessage
