export const apiEndpoints = {
  login: '/auth/signin',
  registeredUsers: '/user',
  subscribedUsers: '/subscribed/user',
  reportedUsers: '/report/user',
  reportedPics: '/report/asset',
  reportedComments: '/report/comment',
  reportedCommentReplies: '/report/comment-reply',
  transactions: '/report/transactions',
  recoverUser: '/recover/user',
  recoverPic: '/recover/asset',
  recoverComment: '/recover/comment',
  recoverCommentReply: '/recover/comment-reply',
  updateTransaction: '/subscribed/update',
  updateProfile: '/admin/profile',
  changePassword: '/admin/change-password',
  requestResetPassword: '/admin-reset/request-reset-password',
  verifyOTP: '/admin-reset/reset-password/verify-otp',
  resetPassword: '/admin-reset/reset-password',
}

export const queryKeys = {
  allRegisteredUsers: 'allRegisteredUsers',
  allSubscribedUsers: 'allSubscribedUsers',
  registeredUsers: 'registeredUsers',
  reportedUsers: 'reportedUsers',
  reportedPics: 'reportedPics',
  reportedComments: 'reportedComments',
  reportedCommentReplies: 'reportedCommentReplies',
}

export const pageTitles = {
  registeredUsers: 'All Users',
  reportedPics: 'Reported Photos',
  reportedUsers: 'Reported Users',
  reportedComments: 'Reported Comments',
  reportedCommentReplies: 'Reported Replies',
  userSubscriptions: 'User Subscriptions',
  settings: 'Settings',
}

export const routes = {
  login: '/login',
  registeredUsers: '/dashboard/users',
  reportedPics: '/dashboard/reported-photos',
  reportedUsers: '/dashboard/reported-users',
  reportedComments: '/dashboard/reported-comments',
  userSubscriptions: '/dashboard/user-subscriptions',
  settings: '/dashboard/settings',
}

export const appVersion = 'v3.0.1'

export const itemsPerPage = 15
