import { Typography, styled as materialStyled } from '@mui/material'

import styled from '@emotion/styled'

export const ModalTitle = materialStyled(Typography)`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: #000;
  line-height: normal;
  margin-top: 24px;
`

export const ModalSubTitle = materialStyled(Typography)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #1f2932;
  line-height: normal;
  margin-top: 16px;
`
export const OTPBorderColor = '#ededed'

export const OTPInput = styled.input`
  border-color: ${OTPBorderColor};
  width: 58px;
  height: 55px;
  border-left: ${({ idx }) =>
    idx === 0
      ? `1px solid ${OTPBorderColor}`
      : `0.5px solid ${OTPBorderColor}`};
  border-right: ${({ idx }) =>
    idx === 3
      ? `1px solid ${OTPBorderColor}`
      : `0.5px solid ${OTPBorderColor}`};
  border-top: 1px solid ${OTPBorderColor};
  border-bottom: 1px solid ${OTPBorderColor};
  border-radius: ${({ idx }) =>
    idx === 0 ? '5px 0px 0px 5px' : idx === 3 ? '0px 5px 5px 0px' : '0px'};
  text-align: center;
`

export const ResendText = materialStyled(Typography)`
  color: ${({ disabled }) => (disabled ? '#999' : '#006ba6')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-left: 12px;
  
`

export const CancelText = materialStyled(Typography)`
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  color: #696969;
  margin-top: 24px;
`
