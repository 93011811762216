import { Button, ListItemText, Menu, MenuItem, Stack, TextField } from "@mui/material"
import FilterList from '@mui/icons-material/FilterList'
import InputAdornment from '@mui/material/InputAdornment'
import { useState } from "react"
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { getFormattedDateFromISO } from '../../utils/DateUtils'
import { ArrowLeftLogo, ArrowRightLogo, CalendarLogo } from '../../assets'

const FilterTransaction = ({ handleSetFilter, handleClearFilter, dataTestId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterTerm, setFilterTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    const { myValue } = event.currentTarget.dataset;
    if (myValue === '1') {
      const firstDate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split("T")[0]
      const lastDate = new Date().toISOString().split("T")[0]
      setFilterTerm('One Month')
      handleSetFilter({
        firstDate,
        lastDate
      })
    } else if (myValue === '6') {
      const firstDate = new Date(new Date().setDate(new Date().getDate() - 30 * 6)).toISOString().split("T")[0]
      const lastDate = new Date().toISOString().split("T")[0]
      setFilterTerm('Six Month')
      handleSetFilter({
        firstDate,
        lastDate
      })
    } else if (myValue === '12') {
      const firstDate = new Date(new Date().setDate(new Date().getDate() - 30 * 12)).toISOString().split("T")[0]
      const lastDate = new Date().toISOString().split("T")[0]
      setFilterTerm('One Year')
      handleSetFilter({
        firstDate,
        lastDate
      })
    } else if (startDate !== '' && endDate !== '') {

      const firstDate = getFormattedDateFromISO(startDate.toISOString(), 'yearFormat')
      const lastDate = getFormattedDateFromISO(endDate.toISOString(), 'yearFormat')

      setFilterTerm(`${firstDate} - ${lastDate}`)
      setStartDate('')
      setEndDate('')
      handleSetFilter({
        firstDate,
        lastDate
      })
    } else {
      setStartDate('')
      setEndDate('')
      setFilterTerm('Filter by date')
      handleClearFilter()
    }
    setAnchorEl(null);
  }

  return (
    <>
      <TextField
        value={filterTerm}
        onChange={handleSetFilter}
        size="small"
        onClick={handleClick}
        disabled
        placeholder="Filter by date"
        sx={{
          width: '24%',
          maxWidth: '280px',
          ml: 2,
          // Decrease input field height
          '& .MuiInputBase-input': { height: '1.4em' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: `${anchorEl && '2px solid #006ba6 !important'}`,
            },
            '&:hover fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.87) !important',
            },    
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FilterList />
            </InputAdornment>
          )
        }}
        inputProps={{
          "data-testid": dataTestId,
        }}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} data-my-value="1">One month</MenuItem>
        <MenuItem onClick={handleClose} data-my-value="6">Six month</MenuItem>
        <MenuItem onClick={handleClose} data-my-value="12">One year</MenuItem>
        <Stack sx={{ paddingLeft: "14px", paddingRight: "14px", width: "400px" }}>
          <ListItemText>Custom date</ListItemText>
          <Stack direction="row" justifyContent="space-between" sx={{ minWidth: 0, marginTop: '14px' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From"
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                sx={{
                  width: '50%',
                  maxWidth: '280px',
                }}
                popperProps={{
                  sx: {
                    "& .MuiPaper-root": {
                      border: "1px solid black",
                    }
                  },
               }}
                slots={{
                  openPickerIcon: CalendarLogo,
                  leftArrowIcon: ArrowLeftLogo,
                  rightArrowIcon: ArrowRightLogo,
                }}
                slotProps={{ textField: { size: 'small' } }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="To"
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                sx={{
                  ml: "8px",
                  width: '50%',
                  maxWidth: '280px',
                }}
                slots={{
                  openPickerIcon: CalendarLogo,
                  leftArrowIcon: ArrowLeftLogo,
                  rightArrowIcon: ArrowRightLogo,
                }}
                slotProps={{ textField: { size: 'small' } }}
              />
            </LocalizationProvider>
          </Stack>
          <Stack direction="row" gap="2" justifyContent="space-between" sx={{ marginTop: "10px" }}>
            <Button variant="outlined" onClick={handleClose} fullWidth sx={{textTransform:"capitalize",borderRadius: 28}}>Apply</Button>
            <Button color="secondary" onClick={handleClose} fullWidth sx={{textTransform:"capitalize"}}>Clear</Button>
          </Stack>
        </Stack>

      </Menu>

    </>
  )
}

export default FilterTransaction