import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { ModalWrapper, Pill } from '../../atoms'
import { LabelValuePair } from '../../molecules'
import { getFormattedDateFromISO, getReportedPicStatus } from '../../../utils'

const ReportedPicDetailsModal = ({
  isPicDetailsModalOpen,
  handleClosePicDetailsModal,
  currentSelectedPic,
  isSmallerScreen,
}) => {
  const LastReportedOn = getFormattedDateFromISO(
    currentSelectedPic?.createdAt,
    'fullDateTimeFormat',
  )

  const ownerFullName =
    currentSelectedPic?.owner.firstName +
    ' ' +
    currentSelectedPic?.owner.lastName

  const reports = currentSelectedPic?.reports || []

  const reportStatus = Number(currentSelectedPic?.reportStatus)
  const assetStatus = Number(currentSelectedPic?.isImageReported)

  const { title, color } = getReportedPicStatus(reportStatus, assetStatus)

  const getFallbackImgSrc = (e) => {
    e.target.src = currentSelectedPic?.assetUrl
  }

  return (
    <ModalWrapper
      width="56vw"
      title="Photo Details"
      showCloseIcon
      isOpen={isPicDetailsModalOpen}
      onClose={handleClosePicDetailsModal}
    >
      {isPicDetailsModalOpen && currentSelectedPic && (
        <>
          <Box
            display="flex"
            flexDirection={isSmallerScreen ? 'column' : 'row'}
            justifyContent="space-between"
            gap={5}
            mt={2}
            sx={{ maxHeight: '80vh' }}
          >
            <Box
              sx={{ overflow: 'auto', width: isSmallerScreen ? '100%' : '45%' }}
            >
              <LabelValuePair label="Owner Full Name" value={ownerFullName} />

              <LabelValuePair
                label="Owner Email"
                value={currentSelectedPic.owner.email || ''}
              />

              <LabelValuePair
                label="Last Reported On"
                value={LastReportedOn || ''}
              />

              <LabelValuePair
                label="Status"
                value={<Pill label={title} colorCode={color} />}
              />

              <Box>
                <Typography
                  color="secondary.main"
                  mb={1}
                  sx={{ textTransform: 'uppercase' }}
                >
                  Reports
                </Typography>

                {reports.map((report) => {
                  const reportCounts = report?.reporters?.length
                  const isMultipleReports = report?.reporters?.length > 1

                  return (
                    <Box key={report.description} width="100%" mb={2}>
                      <Typography noWrap sx={{ whiteSpace: 'normal' }}>
                        {report.description}{' '}
                        {isMultipleReports && (
                          <Typography component="span" variant="body2">
                            ({reportCounts})
                          </Typography>
                        )}
                      </Typography>
                    </Box>
                  )
                })}
              </Box>
            </Box>

            <Box
              display="flex"
              sx={{
                width: isSmallerScreen ? '100%' : '50%',
                maxHeight: '80vh',
                backgroundColor: 'secondary.light',
              }}
            >
              <img
                src={currentSelectedPic?.assetUrlSize480}
                onError={getFallbackImgSrc}
                alt={currentSelectedPic.id}
                loading="lazy"
                style={{ width: '100%', objectFit: 'contain' }}
              />
            </Box>
          </Box>
        </>
      )}
    </ModalWrapper>
  )
}

export default ReportedPicDetailsModal
