import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

const ListSearchInput = ({
  searchTerm,
  handleSetSearchTerm,
  handleClearFilter,
  placeholder,
  width,
  dataTestId,
}) => {
  return (
    <TextField
      value={searchTerm}
      onChange={handleSetSearchTerm}
      size="small"
      placeholder={placeholder || 'Search'}
      sx={{
        width: width || '24%',
        maxWidth: '280px',
        // Decrease input field height
        '& .MuiInputBase-input': { height: '1.4em' },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        ...(searchTerm && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClearFilter}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }),
      }}
      inputProps={{
        "data-testid": dataTestId,
      }}
    />
  )
}

export default ListSearchInput
