import useGlobalStore from '../../store/globalStore'
import { useMutation } from '@tanstack/react-query'
import { axiosPrivate } from '../../api/axiosClient'
import { apiEndpoints } from '../../constants'
import { clearStorage } from '../../utils'

const useChangePassword = (handleCancel) => {
  const { clearStore, showToastMessage } = useGlobalStore((state) => ({
    clearStore: state.clearStore,
    showToastMessage: state.showToastMessage,
  }))

  const { mutate, isLoading } = useMutation(
    (payload) => axiosPrivate.put(`${apiEndpoints.changePassword}`, payload),
    {
      onSuccess: (response) => {
        const successMessage =
          `${response?.data?.message}. Logging out in 5 seconds` ||
          'Successfully updated the password. Logging out in 5 seconds'

        showToastMessage(successMessage, 'success')
        handleCancel()

        setTimeout(() => {
          clearStorage()
          clearStore()
        }, 5000)
      },
      onError: (error) => {
        const errorMessage =
          error?.errors?.message || 'Error occurred while updating the password'

        showToastMessage(errorMessage, 'error')
      },
    },
  )

  return { changePassword: mutate, isUpdatingPassword: isLoading }
}

export default useChangePassword
