import Chip from '@mui/material/Chip'

const Pill = ({ label, colorCode, sxProps, ...rest }) => {
  return (
    <Chip
      label={label || ''}
      sx={{
        backgroundColor: `${colorCode}.extraLight`,
        color: `${colorCode}.main`,
        ...sxProps,
      }}
      {...rest}
    />
  )
}

export default Pill
