import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import FlagIcon from '@mui/icons-material/Flag'
import PersonOffIcon from '@mui/icons-material/PersonOff'
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled'
import PaidIcon from '@mui/icons-material/PaidRounded'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import Typography from '@mui/material/Typography'
import { useLocation, useNavigate } from 'react-router-dom'

import SidebarHeader from './SidebarHeader'
import useGlobalStore from '../../store/globalStore'
import { AppLogo, WoloText } from '../../assets'
import { clearStorage } from '../../utils'
import { appVersion, pageTitles, routes } from '../../constants'

const drawerWidth = 240

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(16)} + 1px)`,
  },
})

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  paddingLeft: theme.spacing(10),
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const Sidebar = ({ open, handleDrawerClose }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const clearStore = useGlobalStore((state) => state.clearStore)

  const sidebarItems = [
    {
      text: pageTitles.registeredUsers,
      route: routes.registeredUsers,
      Icon: <PeopleAltIcon color="primary" />,
    },
    {
      text: pageTitles.reportedPics,
      route: routes.reportedPics,
      Icon: <FlagIcon color="primary" />,
    },
    {
      text: pageTitles.reportedUsers,
      route: routes.reportedUsers,
      Icon: <PersonOffIcon color="primary" />,
    },
    {
      text: pageTitles.reportedComments,
      route: routes.reportedComments,
      Icon: <CommentsDisabledIcon color="primary" />,
    },
    {
      text: pageTitles.userSubscriptions,
      route: routes.userSubscriptions,
      Icon: <PaidIcon color="primary" />,
    },
  ]

  const sidebarSecondMenuItems = [
    {
      id: 1,
      text: pageTitles.settings,
      route: routes.settings,
      Icon: <SettingsIcon color="primary" />,
    },
    {
      id: 2,
      text: 'Logout',
      Icon: <LogoutIcon color="primary" />,
    },
  ]

  const handleNavigation = (toRoute) => navigate(toRoute)

  const handleLogout = () => {
    clearStorage()
    clearStore()
  }

  return (
    <Drawer variant="permanent" open={open}>
      <SidebarHeader open={open}>
        {open && (
          <Box display="flex" alignItems="center" gap={2}>
            <AppLogo width="40px" height="40px" />

            {/* Width and height given to align with app logo */}
            <WoloText width="80px" height="36px" />
          </Box>
        )}

        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </SidebarHeader>

      <Divider />
      <List>
        {sidebarItems.map(({ text, route, Icon }) => (
          <ListItem
            key={text}
            disablePadding
            sx={{ display: 'block' }}
            data-testid="sidebar-list-item"
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 5,
              }}
              onClick={() => handleNavigation(route)}
              selected={route === pathname}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {Icon}
              </ListItemIcon>
              <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />

      <List>
        {sidebarSecondMenuItems.map(({ id, text, route, Icon }) => (
          <ListItem
            key={id}
            disablePadding
            sx={{ display: 'block' }}
            data-testid="sidebar-second-list-item"
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 5,
              }}
              onClick={() => (route ? handleNavigation(route) : handleLogout())}
              selected={route && route === pathname}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {Icon}
              </ListItemIcon>
              <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Box
        display="flex"
        flexDirection="column"
        // Occupy remaining space to push it to the bottom of sidebar
        flexGrow={1}
        alignItems="center"
        justifyContent="flex-end"
        pb={6}
      >
        <Typography color="primary" sx={{ fontWeight: 'bold' }}>
          {open ? `Wolo Admin ${appVersion}` : appVersion}
        </Typography>

        {open && (
          <Typography sx={{ fontSize: '0.75rem' }}>
            © 2022 WOLO Inc. All Right Reserved.
          </Typography>
        )}
      </Box>
    </Drawer>
  )
}

export default Sidebar
