import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InfoIcon from '@mui/icons-material/Info'

const EmptyListInfo = ({ title, height }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height={height || '100vh'}
      alignItems="center"
      justifyContent="center"
    >
      <InfoIcon sx={{ fontSize: '50px' }} />
      <Typography id="modal-modal-title" variant="h6">
        {title}
      </Typography>
    </Box>
  )
}

export default EmptyListInfo
