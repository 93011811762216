import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const LabelValuePair = ({ label, value }) => {
  return (
    <Box mb={4}>
      <Typography
        color="secondary.main"
        sx={{ textTransform: 'uppercase' }}
        data-testid="lvp-label"
      >
        {label}
      </Typography>

      <Typography component="span" color="text.primary" data-testid="lvp-value">
        {value}
      </Typography>
    </Box>
  )
}

export default LabelValuePair
