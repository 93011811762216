import { FormControl, MenuItem, Select } from "@mui/material"

const UserTypeSelect = ({ selectedValue, handleChange, dataTestId }) => {
  return (
    <FormControl size="small"
      sx={{
        width: '15%',
        maxWidth: '126px',
        ml: 2,
        // Decrease input field height
        '& .MuiInputBase-input': { height: '1.1em' },
      }}
    >
      <Select
        id="demo-simple-select"
        value={selectedValue}
        onChange={handleChange}
        inputProps={{
          "data-testid": dataTestId,
        }}
      >
        <MenuItem value='all'>All</MenuItem>
        <MenuItem value='premium'>Premium</MenuItem>
        <MenuItem value='free'>Free</MenuItem>
      </Select>
    </FormControl>
  )
}

export default UserTypeSelect