export const isObjectEmpty = (obj) => Object.keys(obj).length === 0

export const sortArrayByProperty = (
  list = [],
  propertyName,
  sortOrder = 'desc',
) => {
  const newArray = [...list]

  return (
    newArray?.sort((a, b) => {
      const lowerA = a[propertyName]?.toLowerCase()
      const lowerB = b[propertyName]?.toLowerCase()

      if (lowerA < lowerB) return sortOrder === 'desc' ? 1 : -1
      if (lowerA > lowerB) return sortOrder === 'desc' ? -1 : 1
      return 0
    }) || []
  )
}

export const sortByReportPropertyName = (
  list = [],
  propertyName,
  sortOrder = 'desc',
) => {
  const newArray = [...list]

  return (
    newArray?.sort((a, b) => {
      const lowerA = a?.reports && a.reports[0][propertyName]?.toLowerCase()
      const lowerB = b?.reports && b.reports[0][propertyName]?.toLowerCase()

      if (lowerA < lowerB) return sortOrder === 'desc' ? 1 : -1
      if (lowerA > lowerB) return sortOrder === 'desc' ? -1 : 1
      return 0
    }) || []
  )
}